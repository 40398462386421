<script>
import JokeNewView from './JokeNewView'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'JokeEditView',
  extends: JokeNewView,
  methods: {
    getJoke () {
      this.$store.dispatch('joke/fetchOne', this.$route.params.id)
        .then(() => {
          this.joke = this.$store.getters['joke/detail']
        })
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.$store.dispatch('joke/update', this.joke)
        .then(() => {
          if (this.$store.getters['joke/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['topic/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  created () {
    this.getJoke()
  }
}
</script>
