<template>
  <JokeEditView disabled />
</template>

<script>
import JokeEditView from '@/views/joke/JokeEditView'

export default {
  name: 'JokeView',
  components: {
    JokeEditView
  }
}
</script>
